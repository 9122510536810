import styles from './styles.module.scss';

import React from 'react';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {Badge, UncontrolledPopover, PopoverBody} from 'reactstrap';
import {get, keys} from 'lodash/object';
import {isEmpty} from 'lodash/lang';
import NumberFormat from 'react-number-format';
import LabelStatus from './LabelStatus';
import LabelBadge from './LabelBadge';

import {
  ACCOUNT_ROLE_OPTIONS,
  ACCOUNT_STATUSES,
  COMPANY_STATUSES,
  CB_STANDARD_STATUSES,
  ENQUIRY_STATUSES,
  CERTIFICATION_STATUSES,
  WATCH_LIST_STATUSES,
} from 'configs';
import {ACTIVITY_STATUS} from 'configs/data-management';
import {ACCREDITATION_STATUS, ACTIVATION_CODE_STATUS} from 'configs/statuses';

import {CommonDataConsumer} from 'contexts/CommonDataContext';
import {date as getDate} from 'utils/dates';
import {head, tail, truncate} from 'lodash';

export class LabelAccreditationStatus extends React.Component {
  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default:
        color = 'muted';
        break;
      case ACCREDITATION_STATUS.active:
        color = 'success';
        break;
      case ACCREDITATION_STATUS.withdrawn:
        color = 'danger';
        break;
      case ACCREDITATION_STATUS.suspended:
        color = 'warning';
        break;
    }

    return <LabelStatus color={color} status={status} {...rest} />;
  }
}

export class LabelFileStatus extends React.Component {
  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default:
        color = 'muted';
        break;
      case ACTIVITY_STATUS.success:
        color = 'success';
        break;
      case ACTIVITY_STATUS.error:
        color = 'danger';
        break;
    }

    return <LabelStatus color={color} status={status} {...rest} />;
  }
}

export class LabelEnquiryStatus extends React.Component {
  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default:
        color = 'muted';
        break;
      case ENQUIRY_STATUSES.open:
        color = 'success';
        break;
      case ENQUIRY_STATUSES.closed:
        color = 'muted';
        break;
    }

    return <LabelStatus color={color} status={status} {...rest} />;
  }
}

export class LabelAccountStatus extends React.Component {
  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default: // pending, withdrawn, etc...
        color = 'muted';
        break;
      case ACCOUNT_STATUSES.active:
        color = 'success';
        break;
      case ACCOUNT_STATUSES.inactive:
        color = 'warning';
        break;
      case ACCOUNT_STATUSES.suspended:
        color = 'danger';
        break;
    }

    return <LabelStatus color={color} status={status} {...rest} />;
  }
}

export class LabelAccountRole extends React.Component {
  static defaultProps = {
    tag: 'span',
    textOnly: false,
    size: 'md',
    accountRole: 'staff',
  };

  static propTypes = {
    accountRole: PropTypes.oneOf(keys(ACCOUNT_ROLE_OPTIONS)).isRequired,
    textOnly: PropTypes.bool,
    size: PropTypes.oneOf(['sm', 'md', 'lg']),
  };

  render() {
    const {tag, accountRole, textOnly, size, className} = this.props;

    if (textOnly) {
      return ACCOUNT_ROLE_OPTIONS[accountRole] || 'Unknown Status';
    }

    return (
      <Badge
        tag={tag}
        className={classNames(styles['account-role'], {
          [styles[`account-role--${size}`]]: size,
          [styles[`account-role--${accountRole}`]]: accountRole,
          className,
        })}
      >
        {ACCOUNT_ROLE_OPTIONS[accountRole] || 'Unknown Status'}
      </Badge>
    );
  }
}

export class LabelCompanyStatus extends React.Component {
  static propTypes = {
    status: PropTypes.oneOf(keys(COMPANY_STATUSES)).isRequired,
  };

  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default: // pending, withdrawn, etc...
        color = 'muted';
        break;
      case COMPANY_STATUSES.active:
        color = 'success';
        break;
      case COMPANY_STATUSES.inactive:
        color = 'warning';
        break;
      case COMPANY_STATUSES.suspended:
        color = 'danger';
        break;
    }

    return <LabelStatus color={color} status={status} {...rest} />;
  }
}

export class LabelStandardStatus extends React.Component {
  static propTypes = {
    // AB can see deleted status
    status: PropTypes.oneOf(keys(CB_STANDARD_STATUSES)).isRequired,
  };

  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default: // pending, etc...
        color = 'muted';
        break;
      case CB_STANDARD_STATUSES.active:
        color = 'success';
        break;
      case CB_STANDARD_STATUSES.withdrawn:
        color = 'danger';
        break;
      case CB_STANDARD_STATUSES.suspended:
        color = 'warning';
        break;
      case CB_STANDARD_STATUSES.deleted:
        color = 'danger';
        break;
    }

    return <LabelStatus color={color} status={status} {...rest} />;
  }
}

export class LabelWatchListStatus extends React.Component {
  static propTypes = {
    status: PropTypes.oneOf(keys(WATCH_LIST_STATUSES)).isRequired,
  };

  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default: // pending, withdrawn, etc...
        color = 'muted';
        break;
      case WATCH_LIST_STATUSES.active:
        color = 'success';
        break;
      case WATCH_LIST_STATUSES.inactive:
        color = 'warning';
        break;
    }

    return <LabelStatus color={color} status={status} {...rest} />;
  }
}

export class LabelActivationStatus extends React.Component {
  static propTypes = {
    status: PropTypes.oneOf(keys(ACTIVATION_CODE_STATUS)).isRequired,
  };

  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default: // pending, withdrawn, etc...
        color = 'muted';
        break;
      case ACTIVATION_CODE_STATUS.active:
        color = 'success';
        break;
      case ACTIVATION_CODE_STATUS.inactive:
        color = 'warning';
        break;
    }

    return <LabelStatus color={color} status={status} {...rest} />;
  }
}

export class LabelCompanyName extends React.Component {
  static defaultProps = {
    tag: 'h1',
  };

  render() {
    const {tag, acronym, name, to, className} = this.props;

    const companyName = `${name}${acronym ? ` (${acronym})` : ''}`;

    return React.createElement(
      tag,
      {className: className},
      to ? <Link to={to}>{companyName}</Link> : companyName
    );
  }
}

export class LabelCertStatus extends React.Component {
  static propTypes = {
    status: PropTypes.oneOf(keys(CERTIFICATION_STATUSES)).isRequired,
  };

  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default: // pending, withdrawn, etc...
        color = 'muted';
        break;
      case CERTIFICATION_STATUSES.active:
        color = 'success';
        break;
      case CERTIFICATION_STATUSES.inactive:
        color = 'warning';
        break;
      case CERTIFICATION_STATUSES.suspended:
        color = 'danger';
        break;
    }

    return <LabelStatus color={color} status={status} {...rest} />;
  }
}

export class LabelNumber extends React.Component {
  render() {
    return (
      <NumberFormat
        displayType="text"
        value={this.props.value || 0}
        thousandSeparator
      />
    );
  }
}

export class LabelCountry extends React.Component {
  static defaultProps = {
    tag: 'div',
  };

  render() {
    const {tag, className, country_id, limit = 0, children} = this.props;

    if (country_id) {
      return (
        <CommonDataConsumer>
          {({countries}) => {
            const country = countries[country_id];
            const name = get(country, 'name', '');

            const _name = limit ? truncate(name, {length: limit}) : name;

            return tag
              ? React.createElement(
                  tag,
                  {className: classNames(styles['label-country'], className)},
                  _name
                )
              : _name;
          }}
        </CommonDataConsumer>
      );
    }

    return React.createElement(
      tag,
      {className: classNames(styles['label-country'], className)},
      children
    );
  }
}

export class LabelAddress extends React.Component {
  render() {
    const {address, street, city, suburb, state, country, postcode} =
      this.props;

    const fields = [street, city, suburb, state, postcode, country]
      .filter((v) => v)
      .join(', ');

    // prioritize fields over full address if both existing
    // example of this scenario is when user uploaded a file with both full address and individual fields
    if (address && (!!city || !!street)) {
      return fields;
    }

    return address || fields;
  }
}

// This component is used for sites that joins the full address with country
export class LabelAddressSites extends React.Component {
  render() {
    const {address, street, city, suburb, state, country, postcode} =
      this.props;

    const fields = [street, city, suburb, state, postcode, country]
      .filter((v) => v)
      .join(', ');

    // prioritize fields over full address if both existing
    // example of this scenario is when user uploaded a file with both full address and individual fields
    if (address && (!!city || !!street)) {
      return fields;
    }

    const addressWithCountry =
      address && country && [address, country].filter(Boolean).join(', ');

    return addressWithCountry || fields;
  }
}

export class LabelDate extends React.Component {
  static defaultProps = {
    format: 'YYYY-MM-DD',
  };

  render() {
    const {date, timestamp, format} = this.props;

    if (!isEmpty(date)) return getDate(date, format);

    return timestamp ? getDate(timestamp, format) : '-';
  }
}

export class ItemsAndMoreLabel extends React.Component {
  constructor(props) {
    super(props);
    this.labelRef = React.createRef();
  }

  render() {
    const {items, renderItem} = this.props;

    const firstItem = head(items);
    const restItems = tail(items);

    return (
      <>
        {renderItem(firstItem)}
        {restItems.length > 0 && (
          <span
            ref={this.labelRef}
            className="ml-1 text-primary"
            style={{cursor: 'pointer'}}
          >
            + {restItems.length} more
          </span>
        )}
        <UncontrolledPopover
          placement="bottom"
          target={this.labelRef}
          trigger="legacy"
          hideArrow
        >
          <PopoverBody>
            {restItems.map((item, index) => (
              <label key={index} className="font-weight-normal d-block">
                {renderItem(item)}
              </label>
            ))}
          </PopoverBody>
        </UncontrolledPopover>
      </>
    );
  }
}

export class LabelCertStatusBadge extends React.Component {
  static propTypes = {
    status: PropTypes.oneOf(keys(CERTIFICATION_STATUSES)).isRequired,
  };

  render() {
    const {status, ...rest} = this.props;
    let color;

    switch (status) {
      default: // pending, withdrawn, etc...
        color = 'secondary';
        break;
      case CERTIFICATION_STATUSES.active:
        color = 'success';
        break;
      case CERTIFICATION_STATUSES.inactive:
        color = 'warning';
        break;
      case CERTIFICATION_STATUSES.suspended:
        color = 'danger';
        break;
    }

    return <LabelBadge color={color} status={status} {...rest} />;
  }
}
